import { onError } from '@apollo/client/link/error'
import { typeDefs } from '../api/graphql'
import { setContext } from '@apollo/client/link/context'
import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client'
import { GlobalVault } from '../vault/global-vault'
import envConfig from '../../environment'

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error({ message })

      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations, null, 2)}, Path: ${path}`
      )
    })

  if (networkError) console.error(`[Network error]: ${networkError}`)
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const teamCode = GlobalVault.sharedInstance.getItem('teamCode')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      team_code: teamCode ?? void 0
    }
  }
})

const httpLink = new HttpLink({
  // supply the public backend url to here
  uri: envConfig.backendGraphqlUrl
})

export const frontendApolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  typeDefs: typeDefs,
  link: from([errorLink, authLink, httpLink])
})
